import React from "react"
import { Link } from "gatsby"

const getCircleStyle = (link) => {
  let style = {}

  if (link.linkColor) {
    style.backgroundColor = link.linkColor
  }

  if (link.textSize) {
    style.fontSize = link.textSize
  }
  return style
}

const Component = (props) => {
  const {
    poster,
    posterMobile,
    links,
    header,
    desktopWebm,
    desktopMp4,
    mobileWebm,
    mobileMp4,
  } = props

  return (
    <div className="split">
      <div className="bg">
        <div className="bg-image bg-video">
          <video
            autoPlay
            muted
            loop
            playsInline
            className="d-none d-sm-block"
            poster={poster} //desktop poster
          >
            <source src={desktopWebm} type="video/webm" />
            <source src={desktopMp4} type="video/mp4" />
          </video>
          <video
            autoPlay
            muted
            loop
            playsInline
            className="d-sm-none"
            poster={posterMobile} //mobile poster
          >
            <source src={mobileWebm} type="video/webm" /> WebmMobile
            <source src={mobileMp4} type="video/mp4" /> Mp4Mobile
          </video>
        </div>
        {links && (
          <div className="links">
            <div className="container h-100">
              <div className="row h-100 justify-content-center align-items-end item-wrap">
                <div className="col">
                  <div className="row mb-5 mb-sm-0 justify-content-center">
                    <h3 class="start-page-header">{header}</h3>
                    {links.map((link, i) => (
                      <Link
                        to={link.link.url}
                        className="circle-container col-6 col-sm-4 justify-content-center"
                        key={i}
                      >
                        <div
                          className="row circle justify-content-center align-items-center mx-auto"
                          style={getCircleStyle(link)}
                        >
                          <div className="col">{link.link.title}</div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default Component
