import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Split from "../components/StartPage/Split"
import Layout from "../components/Layout"
import Seo from "gatsby-plugin-wpgraphql-seo"

const PageTemplate = (props) => {
  useEffect(() => {
    localStorage.removeItem("posX")
  }, [])

  const {
    data: { wpPage },
    pageContext,
    location,
  } = props

  const pageData = wpPage.template.startPageData
  const pageMeta = wpPage.pageMeta

  const linkData = pageData.links.map((obj) => ({ ...obj, menuLevel: 1 }))

  return (
    <>
      <Seo post={wpPage} />
      <Layout
        location={location}
        title={pageMeta.title}
        description={pageMeta.desc}
        class="split"
        menu={false}
        startMenu={true}
        startMenuLinkData={linkData}
        frontpage={true}
        template={pageContext.template}
        language={pageContext.language}
        page={{ slug: wpPage.slug, uri: wpPage.uri }}
        image={pageMeta?.image?.localFile?.childImageSharp?.fluid}
      >
        <Split
          poster={pageData.backgroundImage.localFile.localURL}
          posterMobile={pageData.backgroundImageMobile.localFile.localURL}
          links={pageData.links}
          header={pageData.startPageHeader}
          desktopWebm={pageData.backgroundVideoDesktopMp4.localFile.localURL}
          desktopMp4={pageData.backgroundVideoDesktopWebm.localFile.localURL}
          mobileWebm={pageData.backgroundVideoMobileWebm.localFile.localURL}
          mobileMp4={pageData.backgroundVideoMobileMp4.localFile.localURL}
        />
      </Layout>
    </>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query page($uri: String!) {
    wpPage(uri: { eq: $uri }) {
      pageMeta {
        desc
        title
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, toFormat: JPG, quality: 85) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
      template {
        ... on WpTemplate_StartPage {
          templateName
          startPageData {
            fieldGroupName
            startPageHeader
            links {
              fieldGroupName
              link {
                target
                title
                url
              }
              linkColor
              textSize
            }
            backgroundImage {
              localFile {
                localURL
              }
            }
            backgroundImageMobile {
              localFile {
                localURL
              }
            }
            backgroundVideoDesktopMp4 {
              localFile {
                localURL
              }
            }
            backgroundVideoDesktopWebm {
              localFile {
                localURL
              }
            }
            backgroundVideoMobileWebm {
              localFile {
                localURL
              }
            }
            backgroundVideoMobileMp4 {
              localFile {
                localURL
              }
            }
          }
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
